<template>
  <div class="signup-questions">
    <template v-if="!isSelectedQuestionHidden">
      <template v-if="selectedQuestion && selectedQuestion.id">
        <div class="sw-block-subtitle text-center">
          {{ selectedQuestion.name }}
        </div>
        <template
          v-if="
            selectedQuestion.details && selectedQuestion.details.description
          "
        >
          <div
            v-html="selectedQuestion.details.description"
            class="pb-2 clearfix text-center"
          ></div>
        </template>
        <ValidationObserver ref="attributeValidationObserver">
          <template v-if="selectedQuestion.type === 'options'">
            <template
              v-if="
                selectedQuestion.details &&
                  selectedQuestion.details.display_type === 'customized'
              "
            >
              <v-card flat max-width="680" class="sw-mx-auto">
                <ValidationProvider
                  rules="required"
                  mode="passive"
                  :name="selectedQuestion.name"
                  v-slot="{ errors }"
                >
                  <CustomizedOptionSelect
                    v-model="userAnswers[selectedQuestion.slug]"
                    :items="selectedQuestion.options"
                    :multi="selectedQuestion.is_collection"
                    :errorMessages="errors[0]"
                  />
                </ValidationProvider>
              </v-card>
            </template>
            <template v-else>
              <v-card
                v-if="selectedQuestion.is_collection"
                flat
                max-width="356"
                class="sw-mx-auto custom-select"
              >
                <ValidationProvider
                  rules="required"
                  mode="passive"
                  :name="selectedQuestion.name"
                  v-slot="{ errors }"
                >
                  <MultiSelect
                    v-model="userAnswers[selectedQuestion.slug]"
                    :items="selectedQuestion.options"
                    :errorMessages="errors[0]"
                  />
                </ValidationProvider>
              </v-card>
              <v-card
                v-if="!selectedQuestion.is_collection"
                flat
                max-width="356"
                class="sw-mx-auto custom-select"
              >
                <ValidationProvider
                  rules="required"
                  mode="passive"
                  :name="selectedQuestion.name"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    v-model="userAnswers[selectedQuestion.slug]"
                    :errorMessages="errors[0]"
                    :items="filterAvailableOptions(selectedQuestion)"
                    :menu-props="{ closeOnContentClick: true }"
                    item-text="name"
                    item-value="id"
                    :placeholder="$vuetify.lang.t('$vuetify.chooseAnswer')"
                    flat
                    background-color="#f2f2f2"
                    class="custom-select rounded-lg registration-field-rounded"
                    solo
                    append-icon="mdi-magnify"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="mr-2 text-overflow--ellipsis">
                        {{ item.name }}
                      </span>
                      <span
                        v-if="item.price"
                        class="mr-2 text-overflow--ellipsis"
                        >({{ formatPrice(item.price, groupCurrency) }})</span
                      >
                    </template>
                    <template slot="item" slot-scope="data">
                      <div class="text-overflow--wrap py-3">
                        <span>{{ data.item.name }}</span>
                        <span v-if="data.item.price" class="ml-2">
                          ({{ formatPrice(data.item.price, groupCurrency) }})
                        </span>
                      </div>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-card>
            </template>
          </template>
          <v-card
            v-if="selectedQuestion.type === 'varchar'"
            flat
            max-width="356"
            class="sw-mx-auto"
          >
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="selectedQuestion.name"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="userAnswers[selectedQuestion.slug]"
                :errorMessages="errors[0]"
                :placeholder="$vuetify.lang.t('$vuetify.writeAnswer')"
                flat
                background-color="#f2f2f2"
                class="rounded-lg registration-field-rounded"
                solo
              ></v-text-field>
            </ValidationProvider>
          </v-card>
          <v-card
            v-if="selectedQuestion.type === 'text'"
            flat
            max-width="356"
            class="sw-mx-auto"
          >
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="selectedQuestion.name"
              v-slot="{ errors }"
            >
              <v-textarea
                v-model="userAnswers[selectedQuestion.slug]"
                :errorMessages="errors[0]"
                :placeholder="$vuetify.lang.t('$vuetify.writeAnswer')"
                no-resize
                flat
                background-color="#f2f2f2"
                class="rounded-lg registration-field-rounded"
                solo
              ></v-textarea>
            </ValidationProvider>
          </v-card>
          <v-card
            v-if="selectedQuestion.type === 'datetime'"
            flat
            max-width="356"
            class="sw-mx-auto"
          >
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="selectedQuestion.name"
              v-slot="{ errors }"
            >
              <date-picker
                :errorMessages="errors[0]"
                v-model="userAnswers[selectedQuestion.slug]"
              >
              </date-picker>
            </ValidationProvider>
          </v-card>
        </ValidationObserver>
      </template>
    </template>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        class="
            sw-mx-2
            text-none
            sw-rounded
            sw-height-48px
          "
        :loading="isLoading"
        :disabled="
          currentAttributeIndex === 0 ||
            (!isLoading && isSelectedQuestionHidden)
        "
        @click="getPreviousAttribute"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.back") }}
        </span>
      </v-btn>
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
            sw-mx-2
            text-none
            white--text
            sw-rounded
            sw-height-48px
          "
        :loading="isLoading"
        :disabled="!isLoading && isSelectedQuestionHidden"
        @click="updateUserAttribute()"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.continue") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/CustomFields/MultiSelect.vue";
import CustomizedOptionSelect from "@/components/CustomFields/CustomizedOptionSelect.vue";
import DatePicker from "@/components/CustomFields/DatePicker.vue";
import EventBus from "@/utils/eventBus";

export default {
  data: () => ({
    isLoading: false,
    relatedAttributesData: [],
    relatedAttributesMeta: {},
    defaultUserAnswers: {},
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store?.commit("SET_USER", val);
      },
    },
    category() {
      return this.$store?.getters["signup/category"];
    },
    userAnswers() {
      return this.user?.attributes || {};
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    plugin: {
      get() {
        return this.$store?.getters["signup/plugin"];
      },
      set(val) {
        this.$store.commit("signup/SET_PLUGIN", val);
      },
    },
    selectedQuestion: {
      get() {
        return this.$store?.getters["signup/selectedQuestion"];
      },
      set(val) {
        this.$store.commit("signup/SET_SELECTED_QUESTION", val);
      },
    },
    isSelectedQuestionHidden() {
      return !!this.selectedQuestion?.hidden;
    },
    currentAttributeIndex() {
      return this.relatedAttributesData.findIndex(
        (el) => el.id === this.selectedQuestion.id,
      );
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store.commit("signup/SET_WINDOW", val);
      },
    },
    signupQuery: {
      get() {
        return this.$store?.getters?.signupQuery;
      },
      set(val) {
        this.$store.commit("SET_SIGNUP_QUERY", val);
      },
    },
  },
  components: {
    MultiSelect,
    CustomizedOptionSelect,
    DatePicker,
  },
  watch: {
    window: {
      immediate: true,
      handler(val) {
        if (val === 3) this.fetchAll();
      },
    },
    appLanguage: {
      handler() {
        this.refetchRelatedAttributes();
      },
    },
  },
  created() {
    this.defaultUserAnswers = { ...this.userAnswers };
  },
  methods: {
    filterAvailableOptions(attribute) {
      if (!attribute || !attribute.options) return [];

      const selectedItem = this.defaultUserAnswers[attribute.slug];

      return attribute.options
        .map((el) => {
          if (el.type === "options" && el.usage_available !== 0) {
            el.options.forEach((opt) => {
              if (selectedItem != null) {
                if (el.is_collection && selectedItem.length) {
                  const contains = selectedItem.find(
                    (v) => v == opt.id || v.id == opt.id,
                  );
                  if (contains) opt.usage_available += 1;
                } else if (selectedItem == opt.id) {
                  opt.usage_available += 1;
                }
              }
            });
          }

          return el;
        })
        .filter((el) => el.usage_available !== 0);
    },
    transformAttributeForEdit(attribute) {
      if (!attribute) return {};

      return {
        ...attribute,
        details: attribute.details ? JSON.parse(attribute.details) : {},
      };
    },
    async listRelatedAttributes() {
      const params = {
        category_id: this.category.id,
        lang: this.appLanguage,
        per_page: 10000,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/list_related`,
        { params },
      );

      const relatedAttributesData = response?.data?.data || [];

      this.relatedAttributesData = relatedAttributesData.map((el) =>
        this.transformAttributeForEdit(el),
      );
      this.relatedAttributesMeta = response?.data?.meta;
    },
    async refetchRelatedAttributes() {
      try {
        if (!this.selectedQuestion.id) return;

        this.isLoading = true;

        await this.listRelatedAttributes();

        this.selectedQuestion = this.relatedAttributesData.find(
          (el) => el.id === this.selectedQuestion.id,
        );

        this.userAnswers[
          this.selectedQuestion.slug
        ] = this.relatedAttributesMeta[this.selectedQuestion.slug];

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    findPreviousVisibleAttribute() {
      for (let i = this.currentAttributeIndex - 1; i >= 0; i--) {
        if (!this.relatedAttributesData[i].hidden) {
          return this.relatedAttributesData[i];
        }
      }
      return this.relatedAttributesData[0];
    },
    async getPreviousAttribute() {
      this.$refs.attributeValidationObserver.reset();

      try {
        this.isLoading = true;

        await this.listRelatedAttributes();

        this.isLoading = false;

        if (this.currentAttributeIndex === 0) return;

        for (let i = this.currentAttributeIndex - 1; i >= 0; i--) {
          if (!this.relatedAttributesData[i].hidden) {
            this.selectedQuestion = this.relatedAttributesData[i];
            break;
          }
        }

        if (!this.selectedQuestion || !this.selectedQuestion.id) {
          this.selectedQuestion = this.relatedAttributesData[0];
        }

        const slug = this.selectedQuestion?.slug;

        this.user.attributes[slug] = this.relatedAttributesMeta[slug];

        this.scrollToEl("#registration-anchor", 65);
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async nextUserAttribute() {
      const params = {
        only_empty: 1,
        category_id: this.category.id,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/next`,
        { params },
      );

      const nextAttribute = response.data.data;

      if (!nextAttribute?.id) {
        // set question from query
        const querySlug = this.signupQuery
          ? Object.keys(this.signupQuery)?.[0]
          : null;

        if (querySlug) {
          const queryAttribute = this.relatedAttributesData?.find(
            (el) => el.slug === querySlug,
          );

          if (queryAttribute?.id) {
            this.selectedQuestion = queryAttribute;

            await this.setAnswerFromQuery();

            return;
          }
        }

        this.signupQuery = null;
        this.selectedQuestion = {};
        this.window = 4;
        return;
      }

      this.selectedQuestion = this.transformAttributeForEdit(nextAttribute);

      await this.setAnswerFromQuery();
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.listRelatedAttributes();

        if (!this.selectedQuestion?.id) {
          await this.nextUserAttribute();
        } else {
          await this.setAnswerFromQuery();
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async updateUserAttribute(skipValidation) {
      try {
        if (!skipValidation) {
          const isValid = await this.$refs.attributeValidationObserver.validate();

          if (!isValid) return;
        }

        this.isLoading = true;

        const slug = this.selectedQuestion.slug;
        const value = this.userAnswers[slug];

        const specs = { [slug]: value };

        await this.$http.post(`/auth/quick_update`, specs);

        const keys = this.signupQuery ? Object.keys(this.signupQuery) : [];

        if (slug === keys[0]) this.signupQuery = null;

        await this.nextUserAttribute();

        this.scrollToEl("#registration-anchor", 65);

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    showSetHiddenAnswerErrorMessage() {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 30000,
        message: this.$vuetify.lang.t("$vuetify.hiddenQuestionErrorMessage"),
      });
    },
    async setAnswerFromQuery() {
      const querySlug = this.signupQuery
        ? Object.keys(this.signupQuery)?.[0]
        : null;
      let queryValue = this.signupQuery?.[querySlug];
      const slug = this.selectedQuestion?.slug;

      if (!querySlug || querySlug !== slug) {
        if (
          this.isSelectedQuestionHidden &&
          !this.userAnswers[slug] &&
          !this.userAnswers[slug]?.length
        ) {
          this.showSetHiddenAnswerErrorMessage();
        }
        return;
      }

      if (Array.isArray(queryValue)) {
        queryValue = queryValue.map((el) => Number(el));
      } else if (
        queryValue &&
        typeof queryValue === "string" &&
        !isNaN(queryValue)
      ) {
        queryValue = Number(queryValue);
      }

      this.userAnswers[slug] = queryValue;

      if (
        this.isSelectedQuestionHidden &&
        !this.userAnswers[slug] &&
        !this.userAnswers[slug]?.length
      ) {
        this.showSetHiddenAnswerErrorMessage();
        return;
      }

      if (!this.isSelectedQuestionHidden) return;

      await this.updateUserAttribute(true);
    },
  },
};
</script>
